<template>
  <div>
    <AddDebitorDlg ref="addDebitorDlg" v-on:Accepted="AddDebitorAccepted"></AddDebitorDlg>
    <AddDebitorObjectDlg ref="addDebitorObjectDlg" v-on:Accepted="AddDebitorObjectAccepted"></AddDebitorObjectDlg>
    <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgContactActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddDebitorContact"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('DEBITORCONTACT.PROMPT.HEADER.TEXT')}}</p>
          <vs-divider>{{$t('DEBITORCONTACT.DIVIDER.DEBITOR_OBJECT')}}</vs-divider>
          <div class="clearfix">
            <div class="float-left mr-3">
              <h5>{{$t('DEBITORCONTACT.HEADER.DEBITOR')}}</h5>
              <p>{{actDebitor.name}}<br/>{{actDebitor.tel}}<br/>{{actDebitor.email}}<br/>{{actDebitor.email2}}</p>
            </div>
            <div class="float-left">
              <h5>{{$t('DEBITORCONTACT.HEADER.DEBITOR_OBJECT')}}</h5>
              <p>{{actDebitorObject.name}}<br/>{{actDebitorObject.street1}} {{actDebitorObject.street2}}<br/>{{actDebitorObject.plz}} {{actDebitorObject.city}}, {{actDebitorObject.country}}</p>
            </div>
          </div>
          <vs-divider>{{$t('DEBITORCONTACT.DIVIDER.DATA')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('DEBITORCONTACT.LABEL.BEZ')"  class="float-left mr-3 inputx" :placeholder="$t('DEBITORCONTACT.LABEL.PLACEHOLDER.BEZ')"  v-model="bez"  :danger="bez_fail" val-icon-danger="clear" />
            <DatePicker Type="datetime" class="float-left" :Label="$t('DEBITORCONTACT.CONTACT_DATETIME')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('DEBITORCONTACT.TITLE.CONTACT_DATETIME')" v-model="contact_at"></DatePicker>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <div class="float-left mr-3">
            <vs-select v-model="reason" :label="$t('DEBITORCONTACT.REASON')" :danger="reason_fail" val-icon-danger="clear" >
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in contactMemberReasons" />
            </vs-select>
            </div>
            <div class="float-left">
            <vs-select v-model="contacttype" :label="$t('DEBITORCONTACT.TYPE')" :danger="contacttype_fail" val-icon-danger="clear">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in contactMemberTypes" />
            </vs-select>
            </div>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('DEBITORCONTACT.INFO_SHORT')"  class="float-left mr-3 inputx" :placeholder="$t('DEBITORCONTACT.LABEL.PLACEHOLDER.INFO_SHORT')"  v-model="info_short"  :danger="info_short_fail" val-icon-danger="clear" />
          </div>
          <vs-textarea height="200px" counter="2000" :label="$t('DEBITORCONTACT.LABEL.INFO_FULL')" :counter-danger.sync="info_full_fail" v-model="info_full" />
        </div>
       </div>
    </vs-prompt>

    <vs-popup
      :active.sync="dlgActive"
      :title="selectDebAndObjPopupTitle"
      id="popupAddDebitorContact">
      <div>
        <vs-tabs v-model="activeTab">
          <vs-tab :label="$t('DEBITORCONTACT.SELECT_DEB')">
            <div class="clearfix">
              <div class="float-left p-3" id="oldDeb">
                <h5 class="mb-2">{{$t('DEBITORCONTACT.SELECT_DEB')}}</h5>
                <vs-select v-model="debitor_fk" autocomplete class="float-right" :label="$t('DEBITORCONTACT.TYPE_SEARCH')">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in debitors" />
                </vs-select>
              </div>
              <div class="float-left p-3"> 
                <h5 class="mb-2">{{$t('DEBITORCONTACT.NEW_DEB')}}</h5>
                <vs-button type="filled" icon="add"  @click="$refs.addDebitorDlg.showDlg()" >{{$t('DEBITORCONTACT.ADD_DEBITOR')}}</vs-button>
              </div>
              
            </div>
            <div class="clearfix"> 
              <div class="btn-alignment" > 
                <vs-button class="mt-3" :disabled="debitor_fk <= 0" @click="activeTab = 2" type="filled" icon="arrow_forward_ios" >{{$t('DEBITORCONTACT.TO_OBJECT')}}</vs-button>
              </div>
            </div>
          </vs-tab>
          <vs-tab :label="$t('DEBITORCONTACT.SELECT_DEB_OBJ')" :disabled="debitor_fk <= 0">
            <div class="clearfix">
              <div class="float-left p-3" id="oldDebObj">
                <h5 class="mb-2">{{$t('DEBITORCONTACT.SELECT_DEB_OBJ')}}</h5>
                <vs-select v-model="debitorobject_fk" autocomplete class="float-right" :label="$t('DEBITORCONTACT.TYPE_SEARCH')">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in debitorobjects" />
                </vs-select>
              </div>
              <div class="float-left p-3"> 
                <h5 class="mb-2">{{$t('DEBITORCONTACT.NEW_DEB_OBJ')}}</h5>
                <vs-button type="filled" icon="add"  @click="$refs.addDebitorObjectDlg.showDlg(debitor_fk)" >{{$t('DEBITORCONTACT.ADD_DEBITOR_OBJ')}}</vs-button>
              </div>
              
            </div>
            <div class="clearfix ">
              <div class="btn-alignment" > 
                <vs-button class="mt-3" type="filled" icon="arrow_back_ios" @click="activeTab = 0">{{$t('COM.BACK')}}</vs-button>
                <vs-button v-show="!SelectDebAndObjOnly" class="mt-3" type="filled" icon="add" :disabled="debitorobject_fk <= 0" @click="ShowDebitorContactDlg()" >{{$t('DEBITORCONTACT.ADD')}}</vs-button>
                <vs-button v-show="SelectDebAndObjOnly" class="mt-3" type="filled" icon="add" :disabled="debitorobject_fk <= 0" @click="SelectDebAndObj()" >{{$t('COM.SELECT')}}</vs-button>
              </div>
            </div>            
          </vs-tab>
        </vs-tabs>
      </div>
    </vs-popup>
  </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import AddDebitorDlg from './AddDebitorDlg';
import AddDebitorObjectDlg from './AddDebitorObjectDlg';
import enumHelper from '../../../helper/enumHelper';
import DatePicker from '../DatePicker.vue';

export default {
  name: "AddDebitorContactDlg",
  components:{
    AddDebitorDlg,
    AddDebitorObjectDlg,
    DatePicker
  },
  props:['SelectDebAndObjOnly'],
   data: function (){
     return {
        dlgActive:false,
        dlgContactActive:false,
        popupTitle:this.$t('DEBITORCONTACT.NEW_CONTACT'),
        debitor_fk:0,
        debitorobject_fk:0,
        activeTab:0,
        idLastTab:2,
        dlgTitle:"",
        info_full_fail:false,
        bez_fail:false,
        bez_ok:false,
        info_short_fail:false,
        info_short_ok:false,
        contacttype_fail:false,
        contacttype_ok:false,
        reason_fail:false,
        reason_ok:false,
        contact_at_fail:true,// weil standard null
        contact_at_ok:false,

        contact_at:null,
        bez:"",
        info_short:"",
        info_full:"",
        contacttype:-1,
        reason:-1,
        id:0,

        actDebitor:{
          name:"",
          tel:"",
          email:"",
          email2:""
        },
        actDebitorObject:{
          name:"",
          street1:"",
          street2:"",
          plz:"",
          city:"",
          country:""
        }
     }
  },
  computed:{
    debitors(){
      return this.GetListOfDebitorsForSelect();
    },
    debitorobjects(){
      return this.GetListOfDebitorObjectsForSelect();
    },
    contactMemberTypes(){
      return this.GetListOfContactMemberTypeForSelect();
    },
    contactMemberReasons(){
      return this.GetListOfContactMemberReasonForSelect();
    },
    actDebitorData(){
      var data = [];
      if(this.$store.state.debitor.singleDebitor.data != null)
      {
        data = this.$store.state.debitor.singleDebitor.data;
      }
      return data;
    },
    actDebitorObjectData(){
      var data = [];
      if(this.$store.state.debitorobject.singleDebitorobject.data != null)
      {
        data = this.$store.state.debitorobject.singleDebitorobject.data;
      }
      return data;
    },
    formValid(){
      return this.bez_ok && this.info_short_ok && this.contacttype_ok && this.reason_ok && !this.info_full_fail && this.contact_at != null;
    },
    selectDebAndObjPopupTitle(){
      if(this.SelectDebAndObjOnly)
      {
        return this.$t('DEBITORCONTACT.POPUP_SELECT_ONLY_TITLE');
      }
      else
      {
        return this.$t('DEBITORCONTACT.POPUP_TITLE');
      }
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ...enumHelper,
    next()
    {
      this.activeTab += 1;
    },
    back(){
      this.activeTab -= 1;
    },
    canceld(){
      this.ClearForm();
    },
    accepted(){

      var data = {
        "debitorcontact":{
          "debitor_fk":this.debitor_fk,
          "debitorobject_fk":this.debitorobject_fk,
          "id":0
        },
        "firstmember":{
          "bez":this.bez,
          "info_short":this.info_short,
          "info_full":this.info_full,
          "contacttype":this.contacttype,
          "reason":this.reason,
          "id":this.id,
          "contact_at":this.contact_at
        }
      };
      var name = this.bez;
      this.$store.dispatch('debitorcontact/saveDebitorContact',data)
      .then(response => {
        if(response.success === true)
        {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.SUCCESS.SAVE').replace("*1*",name)});  
        }
        else
        {
          var text = this.$t('DEBITORCONTACT.WARNING.SAVE').replace("*1*",name);
          text = text.replace("*2*",this.$t(response.text));
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
        }
        this.$emit('Accepted',response.id);
      }, 
      error =>{
          var text = this.$t('DEBITORCONTACT.ERROR.SAVE').replace("*1*",name);
          text = text.replace("*2*",error);
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          this.$emit('Accepted');
      });
      this.ClearForm();
    },
    closed(){
      this.ClearForm();
    },
    showDlg(){
      this.dlgActive = true;
      this.activeTab = 0;
      this.debitorobject_fk = 0;
    },
    AddDebitorAccepted(id){
      this.debitor_fk = id;
    },
    AddDebitorObjectAccepted(id){
      this.debitorobject_fk = id;
    },
    ShowDebitorContactDlg(){
      // Details zum Debitor laden
      this.$store.dispatch('debitor/getDebitor',this.debitor_fk ); 
      // Details zum DebitorObject laden
      this.$store.dispatch('debitorobject/getDebitorObject',this.debitorobject_fk ); 
      this.dlgActive = false;
      this.dlgContactActive = true;
    },
    ClearForm(){
      this.contact_at = null;
      this.bez="";
      this.info_short=""
      this.info_full="";
      this.contacttype=-1;
      this.reason=-1;
      this.id=0;
       
      this.actDebitor.name="";
      this.actDebitor.tel="";
      this.actDebitor.email="";
      this.actDebitor.email2="";
      this.actDebitorObject.name="";
      this.actDebitorObject.street1="";
      this.actDebitorObject.street2="";
      this.actDebitorObject.plz="";
      this.actDebitorObject.city="";
      this.actDebitorObject.country="";

      this.info_full_fail = false;
      this.bez_fail=false;
      this.bez_ok=false;
      this.info_short_fail=false;
      this.info_short_ok=false;
      this.contacttype_fail=false;
      this.contacttype_ok=false;
      this.reason_fail=false;
      this.reason_ok=false;
      this.contact_at_fail=false;
      this.contact_at_ok=true;
    },
    SelectDebAndObj()
    {
      this.$store.dispatch('debitorcontact/getDebitorContactId', {debitor_fk:this.debitor_fk,debitorobject_fk:this.debitorobject_fk})
        .then(contact_fk => {
        
        if(this.IsNotNull(contact_fk))
        {
          if(contact_fk > 0)
          {
            // Kontakt gibts, Auswahl bestätigen, Fenster zu
            this.dlgActive = false;
            this.$emit('DebAndObjSelected',this.debitor_fk,this.debitorobject_fk);
          }
          else
          {
            // Es gibt keine Kontakt dazu, Dialog zur Anlage öffnen
            this.ShowDebitorContactDlg();
          }
        }
      });

      
    }
  },
  watch:{
    debitor_fk(value){
      this.$store.dispatch('debitor/getMyDebitors',{ root: true },{ root: true }); 
      this.$store.dispatch('debitorobject/getDebitorObjectsMin',value);
    },
    actDebitorData(value){
      if(value != null)
      {
        if(this.IsNotNull(value.debitor))
        {
          this.actDebitor.name = this.GetNameFromData(value.debitor);
          this.actDebitor.tel = value.debitor.tel1;
          this.actDebitor.email = value.debitor.email;
          this.actDebitor.email2 = value.debitor.email2;
        }
      }
    },
    actDebitorObjectData(value){
      if(value != null)
      {
        if(this.IsNotNull(value.debitorobject))
        {
          this.actDebitorObject.name = value.debitorobject.name;
          this.actDebitorObject.street1 = value.address1.street1;
          this.actDebitorObject.street2 = value.address1.street2;
          this.actDebitorObject.plz = value.address1.plz;
          this.actDebitorObject.city = value.address1.city;
          this.actDebitorObject.country = this.GetNameOfCountry(value.address1.country_fk);
        }
      }
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    info_short(value)
    {
      this.info_short_fail = value.length == 0;
      this.info_short_ok = !this.info_short_fail;
    },
    contacttype(value)
    {
      this.contacttype_fail = value < 0;
      this.contacttype_ok = !this.contacttype_fail;
    },
    reason(value)
    {
      this.reason_fail = value < 0;
      this.reason_ok = !this.reason_fail;
    },
    contact_at(value)
    {
      this.contact_at_fail = value == null;
      this.contact_at_ok = !this.contact_at_fail;
    }
  }
};
</script>
<style>
div#oldDeb,
div#oldDebObj{
  border-right: 1px solid gray; 
  width:50%; 
  text-align:right;
}
div#promptAddDebitorContact > div.vs-dialog{
  max-width: 800px !important;
}
</style>